<template>
  <div id="base-home createSearch">

    <v-row align="center" justify="center">
      <!-- <v-col cols="12" md="2" sm="2"></v-col> -->
      <v-col cols="11" md="8" style="text-align: justify">

        <p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:24pt; background-color:#ffffff">
          <strong><span style="font-family:Arial; ">Application Terms of Service</span></strong>
        </p>
        <p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
          <span style="font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">Last updated: April 29, 2024</span><br/><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">These Application Terms of Service (“Application Terms”) together with any plan or feature limits, product disclaimers, or other restrictions presented to you in the application from which these terms are linked (“Application”) (collectively, the “Agreement”) govern your access and use of the Application. As used in this Agreement, “CVEA APP,” “Us” or “We” refer to CVEA APP, Platform Provider.</span><br/><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">By downloading, installing, accessing, or using the Application you agree to be bound by this Agreement on behalf of yourself and the organization you represent, if any. If you are accepting on behalf of an organization, do not install or access this Application unless you are authorized to represent that organization. PLEASE READ THIS AGREEMENT CAREFULLY, AS IT CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU AND CVEA APP. If you do not agree to this Agreement, you are not authorized to download, install, access, use, or copy the Application and CVEA APP does not grant you a license, nor any right to access or use the Application.</span><br/><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">THE AGREEMENT CONTAINS PROVISIONS REQUIRING THAT YOU AGREE TO THE USE OF ARBITRATION TO RESOLVE ANY DISPUTES ARISING UNDER THE AGREEMENT RATHER THAN A JURY TRIAL OR ANY OTHER COURT PROCEEDINGS, AND TO WAIVE YOUR PARTICIPATION IN CLASS ACTION OF ANY KIND AGAINST CVEA APP.</span><br/><br/><strong><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">1. Privacy Policy</span></strong><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">All information provided by you or resulting from your use of the Application (excluding any CVEA APP for Teams functionality, which is addressed in Section 4 below) is collected, used, maintained, shared, and destroyed in accordance with the Application Privacy Policy.</span><br/><br/><strong><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">2. Scope of License</span></strong><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">You are granted a non-exclusive, personal, revocable, non-transferable license to use the Application on the device for which it is provided and that you own or control, subject to any terms of service, usage rules, or other terms and conditions provided by the Third Parties (defined below).</span><br/><br/><span
            style="font-family:'Khmer OS Battambang'; background-color:#ffffff">You agree to use the Application, and all related functions or updates provided to you by CVEA APP in accordance with this Agreement and not to modify, decompile, reverse engineer, or create derivative works of the Application. Except as otherwise required by applicable law or regulation, CVEA APP, at any time in its sole discretion and without prior notice, may terminate your user account from our Application, reduce, or suspend your user account from the Application,
        update, revise, or modify the Application, or (iv) Discontinue support for the Application.

        </span>

          <p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
            <span style="font-family:'Khmer OS Battambang'; background-color:#ffffff">The Application is licensed, not sold. It is owned, as applicable, by CVEA (Cambodia Valuer Real Estate Association), their affiliates, agents, or licensors and is protected by Cambodia copyright laws and international treaty provisions. Except for the limited license expressly granted in this Agreement, CVEA APP, their affiliates, agents, or licensors reserve all right, title, and interest in and to the Application. All content, trademarks, services marks, trade names, logos, and icons are proprietary to CVEA APP, their affiliates, agents, or licensors. You may not remove any proprietary notices (e.g., copyright and trademark notices) from the Application.</span><br/><br/><strong><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">

          3. </span></strong><strong><span
              style="font-family:Arial; background-color:#ffffff">CVEA APP for Membership </span></strong>
          </p>
          <p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
            <span style="font-family:'Khmer OS Battambang'">&#xa0;</span>
          </p>
          <p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
            <span style="font-family:'Khmer OS Battambang'; background-color:#ffffff">This application maybe used as part of CVEA membership, all the official membership may access with full functions by subscribe a monthly package under separate agreement call (subscription agreement). With this subscription agreement CVEA members might access a full functions from our application without restrict or limitation. </span>
          </p>
          <p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
            <br/><br/><strong><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">4. Availability</span></strong><br/><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">We do not guarantee the continuous availability of the Application or of any specific feature(s) or services of the Application. CVEA APP will inform you of any significant changes to the Application it may occasionally make. CVEA APP may impose usage or service limits, suspend service, or block certain kinds of usage at our sole discretion. The accuracy and timeliness of data received is not guaranteed; delays or omissions may occur. The speed and quality of the Application may vary and the Application is subject to unavailability, including emergencies, third party service failures, transmission, equipment or network problems or limitations, interference, signal strength, and maintenance and repair. The Application may be interrupted, refused, limited, or curtailed. CVEA APP is not responsible for any failures to maintain the confidentiality, security, accuracy, or quality of your data, messages, or pages whether or not related to interruptions or performance issues with the Application.</span><br/><br/><strong><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">5. User Responsibilities</span></strong><br/><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">A device enabled to access the Internet is required to utilize the Application. You are solely responsible for ensuring that your device is sufficient and compatible for use with the Application. CVEA APP may not be used to misrepresent or to act on behalf of others. All messages you published through the Application shall identify you as the user.</span><br/><br/><span
              style="font-family:'Khmer OS Battambang'; background-color:#ffffff">You will not use the Application to:</span><br/><br/><span
              style="font-family:'Times New Roman'; font-size:12pt">&#xa0;</span>
          </p>
          <ul style="margin:0pt; padding-left:0pt">
            <li style="margin-left:12pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; background-color:#ffffff">
            <span
                style="font-family:'Khmer OS Battambang'; font-size:12pt">Engage in any activities that are illegal;</span>
            </li>
            <li style="margin-left:12pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; background-color:#ffffff">
              <span style="font-family:'Khmer OS Battambang'; font-size:12pt">Transmit content that infringes on any person's intellectual property rights or that is otherwise unlawful;</span>
            </li>
            <li style="margin-left:12pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; background-color:#ffffff">
              <span style="font-family:'Khmer OS Battambang'; font-size:12pt">Alter the attribution of origin in electronic mail messages or posting;</span>
            </li>
            <li style="margin-left:12pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; background-color:#ffffff">
              <span style="font-family:'Khmer OS Battambang'; font-size:12pt">Interfere with, disrupt, alter, or modify the Application, or any part thereof, or create an undue burden on the Application or the networks or services connected to the Application.</span>
            </li>
          </ul>
          <p>
            <br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">You shall at all times abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with your use of the Application, including those related to data privacy, international communications and the transmission of technical or personal data. If CVEA APP is contacted with a complaint regarding or arising from your use of the Application, CVEA APP may deem your service usage in violation of this Agreement and terminate your account without notice.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">6. Subscription Terms, Renewals, and Cancellations</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">Certain features require a subscription via inside the application, as applicable (each, an “googleplay”). Such features (“Paid Application Services”) will be provided to you on a subscription basis for the length of term specified during the subscription process (“Subscription Term”). All of your subscriptions to Paid Application Services will automatically renew for periods equal to your initial Subscription Term, and you will be charged at our then-current rates unless you cancel your subscription through the CVEA app (defined below) prior to your next scheduled billing date.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">7. Billing</span></strong><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">7.1 Recurring Billing</span><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">We not recurring billing every month without your authorization, In order to access full functions you must agreed to subscription and provide us to access your payment through our payment service provider “PayWay” . In the payment method you agreed to provide your credit card information, and made sure your card is valid and sufficient amount. </span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">7.2 Price Changes</span><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">We reserve the right to change the subscription fees that we charge for the Paid Application Services, at any time in our sole discretion, provided that we give you at least thirty (30) days' prior notice of such changes. Unless otherwise specified in such notice to you, any changes to the subscription fees will take effect in the billing period immediately following our notice to you.</span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">7.3 Cancellation</span><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">If you do not want your subscription to renew automatically, or if you want to change or terminate your subscription, you must log in to your account and follow the instructions to manage or cancel your subscription, even if you have otherwise deleted your account with us or if you have deleted the Application from your device. </span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">7.4 No Refunds</span><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">SUBSCRIPTION FEES ARE SUBJECT TO THE APP STORE REFUND POLICIES AND ARE ISSUED SOLELY AT THE DISCRETION OF THE APP STORE. YOU WILL BE BILLED IN FULL FOR THE SUBSCRIPTION TERM IN WHICH YOU CANCEL AND NO REFUNDS WILL BE PROVIDED FOR THE UNUSED PORTION OF SUCH SUBSCRIPTION TERM. We may, in our sole discretion, provide a refund, discount, or credit (“Credits”) to you in a specific instance, however the provision of Credits in a specific instance does not entitle you to Credits in the future for similar instances or obligate us to provide additional Credits.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">8. Referral Program</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">we don't any referral program yet at this moment.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">9. Third Parties</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">The Agreement forms a contract between you and CVEA APP, as applicable. The Third Parties, do not own and are not responsible for the Application. The Third Parties are not providing any warranty for the Application. They are not responsible for maintenance or other support services for it and shall not be responsible for any other claims, losses, liabilities, damages, costs or expenses with respect to the Application. Any inquiries or complaints relating to the use of the Application, must be directed to the correct address provided in the “contact information” below.</span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">By accepting this Agreement, you also accept that you comply with all applicable Third Party terms of agreement when using the Application. You acknowledge and agree that the Third Parties and their subsidiaries, are third party beneficiaries of this Agreement and that, upon your acceptance of the terms and conditions of it, they will have the right to enforce this Agreement against you as a third party beneficiary of this Agreement.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">10. Beta Services</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">From time to time, we may released any new functionality to improve our services, each of functionality may require additional pay or free for testing. You also agreed if additional fee will apply to your account as state on additional application service.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">11. Disclaimer of Warranties</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">THE APPLICATION IS MADE AVAILABLE TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. AS SUCH, YOUR USE OF THE APPLICATION IS AT YOUR OWN DISCRETION AND RISK. CVEA APP MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, ACCURACY, OR RELIABILITY OF THE APPLICATION, ITS SAFETY OR SECURITY, OR THE SERVICES CONTENT. ACCORDINGLY, CVEA APP IS NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE APPLICATION'S INOPERABILITY, UNAVAILABILITY, OR SECURITY VULNERABILITIES. CVEA APP EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">12. Limitation of Liability</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL CVEA APP BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY, WHETHER OR NOT CVEA APP HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.</span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">YOU AGREE THAT THE AGGREGATE LIABILITY OF CVEA APP TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF RELATING TO THE USE OF OR ANY INABILITY TO USE THE APPLICATION (INCLUDING ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICES) OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO AND SHALL NOT EXCEED THE GREATER OF THE AMOUNTS YOU PAID TO CVEA APP FOR ACCESS TO THE APPLICATION AND $0.1111.</span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">13. Third Party Software</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">The Application and future enhancements may contain certain third party components (“Third Party Components”) which may be provided to you under terms and conditions which are different from this Agreement, or which require CVEA APP to provide you with certain notices and/or additional information. Your use of each Third Party Component will be subject to the terms and conditions of those other terms and conditions. CVEA APP MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, WITH REGARD TO ANY THIRD-PARTY COMPONENTS. ALL THIRD-PARTY COMPONENTS ARE PROVIDED “AS-IS,” WITHOUT WARRANTIES OF ANY KIND. IN NO EVENT WILL CVEA APP BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE THIRD PARTY COMPONENTS, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">14. Messaging</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">As part of the Application, you may receive push notifications, text messages, alerts, or other types of messages sent to you outside or inside the Application (“Push Messages”). You have control over the Push Messages settings, and can opt in or out of these Push Messages through the Application or through your device's operating system (with the exception of Urgent Notifications described above).</span><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">Please be aware that messaging fees may apply depending on the message plan you have with your wireless carrier. CVEA APP disclaims all liability for Push Messages sent erroneously due to technical failures or errors.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">15. Application Updates</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">CVEA APP may require you to update your version of the Application at any time. While effort will be made to retain your personal settings and preferences, there is still the possibility that they may be lost.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">16. Changes to the Terms</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">CVEA APP reserves the right to make modifications to this Agreement at any time. If a revision materially alters your rights we will use reasonable efforts to make such information available to you. In some instances, you may be required to indicate your consent to the revised terms in order to continue accessing the Application. Unless otherwise specified, any modifications to this Agreement will take effect at the start of Subscription Term following the notice. If you do not agree with the revised terms, your sole and exclusive remedy will be not to renew your Subscription. It is your responsibility to visit the terms section of the Application to review the most current terms and conditions.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">17. Governing Law</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">This Agreement is governed by the laws of the Kingdom of Cambodia without regard to conflict of law principles. To the extent that any lawsuit or court proceeding is permitted hereunder, you and CVEA APP agree to submit to the personal and exclusive jurisdiction of the KINGDOM OF CAMBODIA.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">18. Contact Information</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">This Application is operated by CVEA APP APP , our office located at N</span>o
            <span style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">05, Street 1982(Oknha Mongrithy Blvd), Sangkat Phnom Pneh Thmey, Khan Sen Sok zip code: 120801, Phnom Penh, Cambodia, 12150. You may contact us by sending correspondence to the foregoing address (Attention: Admin Department) or by emailing us at </span><a
              href=""
              style="text-decoration:none"><span class="Hyperlink"
                                                 style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff"><span
              class="__cf_email__"
              data-cfemail="1f6c6a6f6f706d6b5f7c697a7e31706d78317477">support@cvea.org.kh </span></span></a><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">&#xa0; </span><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">any “abuse” complaints can be directed to </span><a
              href="" style="text-decoration:none"><span
              class="Hyperlink"
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff"><span
              class="__cf_email__" data-cfemail="e980878f86a98a9f8c88c7869b8ec78281">info@cvea.org.kh</span></span></a>
          </p>
          <p>
            <br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">19. Miscellaneous</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">This Agreement and the related terms referenced above constitute the entire understanding and agreement between us and you with respect to the transactions contemplated in this Agreement and supersedes all prior or contemporaneous oral or written communications with respect to the subject matter of this Agreement, all of which are merged into this Agreement. Except as provided above, this Agreement may not be modified, amended or in any way altered except by an instrument in writing signed by authorized representatives of both parties. In the event any provision of this Agreement is found invalid or unenforceable pursuant to judicial decree, the remainder of this Agreement will remain valid and enforceable according to its terms. Any failure by CVEA APP to strictly enforce any provision of this Agreement will not operate as a waiver of that provision or any subsequent breach of that provision. The disclaimers and limitations of liability will survive any termination or expiration of this Agreement.</span><br/><br/><strong><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">20. User data privacy</span></strong><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">All users are downloading CVEA we not stored any information unless you register to become a member. To become a member our platform will require your phone number, with associated your country location. So our platform will store only phone number you provide as main requirements.</span><br/><br/><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff">After you are no longer use CVEA APP you can request to our support team to delete your data via email </span><a
              href=""
              style="text-decoration:none"><span class="Hyperlink"
                                                 style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff"><span
              class="__cf_email__"
              data-cfemail="0d7e787d7d627f794d6e7b686c23627f6a236665">support@cvea.org.kh</span></span></a><span
              style="line-height:108%; font-family:'Khmer OS Battambang'; font-size:12pt; background-color:#ffffff"> or you might be able to delete your account from your account profile page.</span>
          </p>

      </v-col>
      <!-- <v-col cols="12" md="2" sm="2"></v-col> -->

    </v-row>
  </div>

</template>


<script>
export default {
  name: "termofuse",
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {


  }

};
</script>


